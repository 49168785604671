@import url('https://fonts.googleapis.com/css?family=Annie+Use+Your+Telescope&display=swap');

@font-face {
    font-family: 'helios';
    src: url('../fonts/helios_regular-webfont.woff2') format('woff2'),
         url('../fonts/helios_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@300&family=Inter:wght@200;400;800&display=swap');

.font-inter-400 {
	font-family: 'Dosis', sans-serif;
	font-family: 'Inter', sans-serif;
	font-weight: 400;
}
.font-inter-600 {
	font-family: 'Dosis', sans-serif;
	font-family: 'Inter', sans-serif;
	font-weight: 600;
}

.font-helios {
	font-family: 'helios', sans-serif;
	font-weight: normal;
	font-style: normal;
}

.handwriting {
	font-family: 'Annie Use Your Telescope', cursive;
	font-weight: normal;
}

.App {
	text-align: left;
	font-family: 'Inter', Helvetica, Arial, sans-serif;
	font-weight: normal;
	font-style: normal;
	overflow-x: hidden;
	font-weight: 400;
	width: 100vw;
}
.row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.foot-spacer {
	height: 100px;
	@media (min-width: 600px) {
		height: 0px;
	}
}

p {
	color: #c1c1c1;
}

.position {
	font-size: 18px;
	position: fixed;
	left: 20px;
	top: 20px;
	color: #c1c1c1;
}

a:link,
a:active,
a:visited {
	color: #252525;
	text-decoration: none;
	padding-bottom: 0px;
	border-bottom: 1px solid rgb(203, 133, 2);
}
a:hover {
	background: linear-gradient(159deg, rgb(217, 157, 97) 0%, rgb(172, 87, 31) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	border-bottom: 1px dashed #c3c3c3;
	cursor: pointer;
}

.text-size-medium {
	font-size: 1.4rem;
}

.container {
	/* max-width: 1390px; */
	text-align: left;
}

.color-highlight {
	color: #d57b46;
}
.color-highlight_dark {
	color: #d57b46;
}

.color-bg {
	background-color: #383c3d;
	background: linear-gradient(200deg, #383c3d 40%, #3d2d2a 100%);

}

::selection {
	color: #fff;
	background: rgb(192, 169, 99);
}
