html,body {
	background-color: #383c3d;
}

body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
	overflow: hidden;
	width: 100vw;
}

::-webkit-scrollbar {
	width: 4px;
	background-color: #f5f5f5;
}
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.45);
	background-color: #909090;
}

::-webkit-scrollbar-thumb {
	/* background-color: #00ff00; */
	border: 3px solid rgb(200, 139, 59);
}
