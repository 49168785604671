.itemList {
    margin: 0;
    min-height: 70vh;
    padding: 20px 20px;
    /* background: yellow; */
  }

.item-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
  